
<template>
    <div class="puzzlelevels">
        <span>Difficaulty</span>
        <div v-for="l in levels"
            :key="l"
            :class="level == l ? 'puzzlelevel active' : 'puzzlelevel'"
            @click="$emit('set-level', l)">
            <span></span>{{ l }}
        </div>
    </div>
</template>
<script>
export default {
    name    : "levelpicker",
    props   : {
        level    : { type: Number },
        levels  : { type: Array },
    }
}
</script>